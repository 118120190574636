<template>
    <div class="print">
        <h3 class="title">
            扫描时自动打印：
            <el-checkbox-group v-model="printList" @change="init">
                <el-checkbox :label="1">打印发货单</el-checkbox>
                <el-checkbox :label="2">制卡</el-checkbox>
            </el-checkbox-group>
            <span class="select-label">发货单打印机：</span>
            <el-select v-model="curPrint" placeholder="请选择打印机" @change="setPrint()">
                <el-option v-for="(item, index) in PrintList" :key="index" :label="item" :value="item">
                </el-option>
            </el-select>
            <el-button class="getPrint-btn" type="primary" @click="getPrintList()">获取打印机选项</el-button>
        </h3>
        <div class="card">
            <div class="top mb30">
                请扫描订单编号：
                <div class="input-box">
                    <el-input @focus="isFocus = true" @blur="isFocus = false" v-model="search" placeholder="订单编号"
                        ref="order" @keydown.enter.native="getOrder"></el-input>
                </div>
                <p class="tips" v-if="!isFocus">请将光标放入输入框内</p>
            </div>
            <div class="table">
                <el-table :data="list" style="width: 100%" v-loading="loading" height="456">
                    <el-table-column prop="order_sn" label="订单编号" width="120" align="center" />
                    <!-- <template slot-scope="scope">
                {{ scope.row.name }}
              </template> -->
                    <el-table-column prop="hospital" label="医院" width="140" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.hospital ? scope.row.hospital.title : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="doctor" label="医生" width="140" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.doctor ? scope.row.doctor.title : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="患者姓名" width="80" align="center">
                    </el-table-column>
                    <el-table-column prop="date" label="牙位" width="330" align="center">
                        <template slot-scope="scope">
                            <div class="y-wei">
                                <div class="box" v-for="(item, index) in ywList" :key="index">
                                    <div class="item" :class="{
                                        active: isSelectTable(subItem.id, scope.row.tooth),
                                    }" v-for="subItem in item" :key="subItem.id">
                                        {{ subItem.lable }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ptitle" label="产品名称" width="140" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.ptitle ? scope.row.ptitle.title : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="num" label="数量" width="140" align="center" />
                </el-table>
            </div>
            <!-- <div class="card-demo" id="cardDemo">
                <div class="shu" v-for="item in 100" :key="item" :style="{
                    left: (item - 1) * 9 + 'px'
                }"></div>
                <div class="hen" v-for="item in 100" :key="item" :style="{
                    top: (item - 1) * 9 + 'px'
                }"></div>
            </div> -->
            <!-- <div class="bottom">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.currentPage" :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size" layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div> -->
        </div>
    </div>
</template>
<script>
import "@/common/js/jquery-1.8.0.min.js";
import "@/common/js/Print.js";
import "@/common/js/JsBarcode.js";
import { getLodop } from "@/common/js/LodopFuncs.js";
import hhPrint from "@/common/js/hhPrint.js";
import { v4 as uuidv4 } from "uuid";
import request from "@/common/js/request.js";
import moment from "moment";

export default {
    name: "info",
    data() {
        return {
            search: "",
            printList: [1],
            ywList: [
                [
                    { id: "1", lable: "18" },
                    { id: "2", lable: "17" },
                    { id: "3", lable: "16" },
                    { id: "4", lable: "15" },
                    { id: "5", lable: "14" },
                    { id: "6", lable: "13" },
                    { id: "7", lable: "12" },
                    { id: "8", lable: "11" },
                ],
                [
                    { id: "9", lable: "21" },
                    { id: "10", lable: "22" },
                    { id: "11", lable: "23" },
                    { id: "12", lable: "24" },
                    { id: "13", lable: "25" },
                    { id: "14", lable: "26" },
                    { id: "15", lable: "27" },
                    { id: "16", lable: "28" },
                ],
                [
                    { id: "17", lable: "48" },
                    { id: "18", lable: "47" },
                    { id: "19", lable: "46" },
                    { id: "20", lable: "45" },
                    { id: "21", lable: "44" },
                    { id: "22", lable: "43" },
                    { id: "23", lable: "42" },
                    { id: "24", lable: "41" },
                ],
                [
                    { id: "25", lable: "31" },
                    { id: "26", lable: "32" },
                    { id: "27", lable: "33" },
                    { id: "28", lable: "34" },
                    { id: "29", lable: "35" },
                    { id: "30", lable: "36" },
                    { id: "31", lable: "37" },
                    { id: "32", lable: "38" },
                ],
            ],
            loading: false,
            pagination: {
                sizes: [10, 30, 50, 100],
                size: 10,
                total: 0,
                currentPage: 1,
            },
            list: [],
            curInfo: {},
            mould: '',
            isFocus: false,
            PrintList: [],
            curPrint: localStorage.getItem('curPrint') ? localStorage.getItem('curPrint') : ''
        };
    },
    created() {
        // 获取发货单模板
        this.getMould();
    },
    mounted() {
        this.init()
        // Print($("#cardDemo")[0], {
        //     onEnd: function () {
        //         console.log('onEnd', new Date())
        //     }
        // })
        // this.getList();
    },
    methods: {
        setPrint() {
            localStorage.setItem('curPrint', this.curPrint)
        },
        init() {
            this.$refs.order.focus()
            // 解决重复监听
            // setTimeout(() => {
            //     this.getPrintList()
            // }, 1000)
        },
        getPrintList() {
            let LODOP = getLodop()
            let listCount = LODOP.GET_PRINTER_COUNT() //当前打印设备数量
            let list = []
            for (var i = 0; i < listCount; i++) {
                //根据设备序号获取设备名
                list.push(LODOP.GET_PRINTER_NAME(i))
            }
            this.PrintList = list
        },
        getOrder() {
            const arr = this.search.split(" ")
            const str = arr[arr.length - 1]
            this.search = ""
            this.init()
            this.getInfo(str)
            // console.log(str)
        },
        getInfo(order_sn) {
            this.loading = true
            let type = 0
            if ((this.printList.findIndex(item => item == 1) != -1) && this.curPrint) {
                type = 1
            }
            if (this.printList.findIndex(item => item == 2) != -1) {
                type = 2
            }
            if ((this.printList.findIndex(item => item == 1) != -1) && this.curPrint && (this.printList.findIndex(item => item == 2) != -1)) {
                type = 3
            }

            this.$api.byOrderGetInfo({
                order_sn,
                type
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    const num = res.data.tooth.split(',').length
                    this.curInfo = Object.assign({}, res.data, { newTime: moment().format("YYYY-MM-DD"), num })
                    this.list.unshift(this.curInfo)
                    const info = {
                        order_sn: this.curInfo.order_sn,
                        receive_date: this.curInfo.receive_date,
                        user_name: this.curInfo.user ? this.curInfo.user.name : '',
                        organization: this.curInfo.user ? this.curInfo.user.organization : '',
                        habits: this.curInfo.user ? this.curInfo.user.habits : '',
                        name: this.curInfo.name,
                        doctor: this.curInfo.doctor ? this.curInfo.doctor.title : '',
                        hospital: this.curInfo.hospital ? this.curInfo.hospital.title : '',
                        product: this.curInfo.ptitle ? this.curInfo.ptitle.title : '',
                        num: this.curInfo.num,
                        tooth: this.curInfo.tooth,
                        color: this.curInfo.color,
                        design_req: this.curInfo.design_req,
                        amount: this.curInfo.product_price,
                        block_code: this.curInfo.block_code,
                        remark: this.curInfo.remark,
                        area_name: this.curInfo.user ? this.curInfo.user.area_name : '',
                        user_type_name: this.curInfo.user ? this.curInfo.user.user_type_name : '',
                        newTime: this.curInfo.newTime,
                        attribute: this.curInfo.attribute,
                    }
                    console.log(info)
                    if (this.printList.findIndex(item => item == 1) != -1) {
                        // console.log(JSON.parse(this.mould))
                        if (!this.curPrint) {
                            this.$message({
                                showClose: true,
                                message: "请先选择发货单打印机",
                                type: "error",
                                duration: 1000,
                            });
                            return
                        }
                        hhPrint.printLocal(this.mould, [info], this.curPrint)
                        // this.$api.huipeng({
                        //     sn: "960815108",
                        //     content: hhPrint.printFE(this.mould, [info]),
                        // })
                    }
                    if (this.printList.findIndex(item => item == 2) != -1) {
                        hhPrint.printCard(this.curInfo.card.card.content_json, info).then(res => {
                            this.init()
                        })
                    }
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "success",
                        duration: 1000,
                    });
                } else {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                        duration: 1000,
                    });
                }
            })
        },
        getMould() {
            this.loading = true
            this.$api.getMould().then(res => {
                this.loading = false
                if (res.code == 200) {
                    // this.mould = res.data.info.content_json
                    this.mould = res.data.info.content_json_local
                } else {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                        duration: 1000,
                    });
                }
            })
        },
        isSelectTable(id, arrStr) {
            if (!arrStr) {
                return false;
            }
            let arr = arrStr.split(",");
            let index = arr.findIndex((item) => item == id);
            if (index == -1) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>
  
<style lang="scss">
.green {
    .el-input__inner {
        color: #2d8cf0 !important;
    }
}

.print {
    .card {
        .input-box {
            display: flex;
        }

        .top {
            .el-input__inner {
                width: 260px;
                margin-right: 10px;
            }

            .el-input__suffix {
                right: 10px;
            }

            .tips {
                font-size: 12px;
                color: red;
            }
        }

        .big-box {
            .el-input__inner {
                width: 280px;
                margin-right: 10px;
            }

            .el-date-editor .el-range-separator {
                padding: 0;
            }
        }

        .small-box {
            .el-input__inner {
                width: 130px;
                margin-right: 10px;
            }
        }

        .bottom {
            .el-pager {
                .number {
                    border: 1px solid #ebebeb;
                    margin-right: 4px;

                    &:hover {
                        color: #2d8cf0;
                    }

                    &.active {
                        background: #2d8cf0;
                        border: 1px solid #2d8cf0;
                        color: #ffffff;
                    }
                }
            }
        }

        .el-table th {
            background: #eef4f1;
            color: #333333;
        }
    }

    .add-form {
        .el-form-item {
            margin-bottom: 22px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .el-form-item--medium .el-form-item__content {
            margin-left: 0 !important;
        }

        .el-input__inner {
            width: 420px;
        }

        .fj-box {
            display: flex;
            flex-wrap: wrap;

            .fj-item {
                line-height: 110px;
                margin-right: 15px;
                padding: 0 10px;
                position: relative;

                .del {
                    position: absolute;
                    width: 26px;
                    height: 16px;
                    top: 30px;
                    right: -10px;
                    object-fit: contain;
                    cursor: pointer;
                }
            }
        }

        .upload-btn {
            width: 110px;
            height: 110px;
            background: #f1f9f5;
            border: 1px dashed #9a9c9b;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 1.3;

            .icon {
                width: 26px;
                height: 26px;
                margin-bottom: 12px;
            }
        }

        .y-wei {
            width: 420px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .box {
                display: flex;
                padding-right: 8px;
                border-bottom: 1px dashed #979797;
                border-right: 1px dashed #979797;
                padding-bottom: 10px;

                &:nth-child(2n) {
                    padding-right: 0;
                    border-right: none;
                    padding-left: 10px;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    border-bottom: none;
                    padding-bottom: 0;
                    padding-top: 10px;
                }

                .item {
                    width: 23px;
                    line-height: 27px;
                    background: #eef4f1;
                    border-radius: 5px;
                    margin-right: 2px;
                    cursor: pointer;
                    text-align: center;
                    font-size: 16px;
                    color: #333333;

                    &.active {
                        background: #2d8cf0;
                        color: #fff;
                    }
                }
            }

            .select-top-all {
                position: absolute;
                right: -60px;
                top: -2px;
                color: #333;
            }

            .select-bottom-all {
                position: absolute;
                right: -60px;
                bottom: -2px;
                color: #333;
            }
        }
    }

    .el-button--primary {
        background: #2d8cf0 !important;
        border-color: #2d8cf0 !important;
    }
}
</style>
<style lang="scss" scoped>
.card-demo {
    width: 324px;
    height: 207px;
    background-color: pink;
    overflow: hidden;
    position: relative;

    .shu {
        position: absolute;
        height: 207px;
        width: 9px;
        border-right: 1px solid #000;
    }

    .hen {
        position: absolute;
        height: 9px;
        width: 324px;
        border-bottom: 1px solid #000;
    }
}

.add-form {
    ::v-deep .el-radio {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.print {
    width: 1150px;
    margin: 20px auto 0;

    .title {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .select-label {
            font-size: 20px;
            font-weight: bold;
            margin-left: 40px;
        }

        .getPrint-btn {
            margin-left: 15px;
        }
    }

    .card {
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        padding: 30px 30px 40px;
        position: relative;
        z-index: 1;

        .top {
            &.mb30 {
                margin-bottom: 20px;
            }

            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .search-btn {
                width: 90px;
                line-height: 36px;
                background: #2d8cf0;
                border-radius: 5px;
                text-align: center;
                font-size: 14px;
                color: #ffffff;
                // margin-right: 30px;
                cursor: pointer;
            }

            .add-btn {
                width: 126px;
                height: 36px;
                background: #2d8bf010;
                border: 1px solid #2d8cf0;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #2d8cf0;
                cursor: pointer;
                margin-right: 10px;

                .img {
                    margin-right: 6px;
                }
            }
        }

        .table {
            margin-bottom: 35px;

            .y-wei {
                width: 320px;
                display: flex;
                flex-wrap: wrap;

                .box {
                    display: flex;
                    padding-right: 8px;
                    border-bottom: 1px dashed #979797;
                    border-right: 1px dashed #979797;
                    padding-bottom: 10px;
                    width: 160px;
                    height: 32px;

                    &:nth-child(2n) {
                        padding-right: 0;
                        border-right: none;
                        padding-left: 10px;
                    }

                    &:nth-child(2n + 1) {
                        justify-content: flex-end;
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        border-bottom: none;
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    .item {
                        display: none;
                        width: 16px;
                        line-height: 20px;
                        // background: #eef4f1;
                        border-radius: 5px;
                        margin-right: 2px;
                        cursor: pointer;
                        text-align: center;
                        font-size: 14px;
                        color: #333333;

                        &.active {
                            display: block;
                            // background: #2D8CF0;
                            // color: #fff;
                        }
                    }
                }
            }

            .file-box {
                display: flex;
                flex-wrap: wrap;

                .file-item {
                    line-height: 1.5;
                    margin-right: 8px;
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
        }
    }
}
</style>